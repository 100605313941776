import Clove from "../assets/clove.jpg";
import Cashew from "../assets/cashew.jpg";
import Ragi from "../assets/ragi.jpg";

export const MenuList = [
  {
    name: "Clove",
    image: Clove,
    price: "Indonesian Lal Pari",
  },
  {
    name: "Cashew",
    image: Cashew,
    price: "All types of Cashew",
  },

  {
    name: "Ragi",
    image: Ragi,
    price: "Ragi",
  },
];
